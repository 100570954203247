import React from 'react';
import { Navbar, Nav, Container } from "react-bootstrap";
import junta from "../images/junta.png";
import ecyl from "../images/ecyl.png";
import ministerio from "../images/ministerio.png";

function Footer(){
    return (
        <Navbar bg="light" always="true" expand="lg" fixed="botton">
            <Container>
                <Navbar.Brand href="https://itagraformacion.com/"><p className="Itagra">Copyright: Itagra Formación</p></Navbar.Brand>

                <Nav className="navbar-collapse justify-content-center">
                    <Navbar.Brand href="https://fundacionsanisidromicas.com/Anexo_IV_Politica_calidad.pdf"><p className="Itagra">Política de calidad</p></Navbar.Brand>
                    <Navbar.Brand href="https://fundacionsanisidromicas.com/politica-cookies.pdf"><p className="Itagra">Política de cookies</p></Navbar.Brand>
                    <Navbar.Brand href="https://fundacionsanisidromicas.com/politica-privacidad.pdf"><p className="Itagra">Política de privacidad</p></Navbar.Brand>
                </Nav>

                <Nav className="navbar-collapse justify-content-end">
                    <Nav.Item><img src={junta} alt="Junta de Castilla y León"/></Nav.Item>
                    <Nav.Item><img src={ecyl} alt="ECYL"/></Nav.Item>
                    <Nav.Item><img src={ministerio} alt="Ministerio de España"/></Nav.Item>
                </Nav>

            </Container>
        </Navbar>
    );
}

export default Footer;
import React from 'react';
import Nosotros from '../components/nosotros';
import Programas from '../components/programas';
import Contacto from '../components/contacto';
import Blog from '../components/blog';
import { Container } from "react-bootstrap";
import Video from '../components/video';
import Donacion from '../components/donar.jsx';
import Ecoregimenes from '../components/ecoregimenes.jsx';

function Home(){
    return (
        <Container fluid>
            <Video/>
            <Nosotros/>
            <Ecoregimenes/>
            <Programas/>
            <Blog/>
            <Contacto/>
            <Donacion/>
        </Container>
    );
}

export default Home;
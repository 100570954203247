import React, { useEffect } from 'react';
import { Row, Col, Container, Card, ListGroup } from "react-bootstrap";
import ecoregimenes from "../images/eco-regimenes-250624.jpg";
import placa from "../images/Placa-web-informativa-FSIM-2024.jpg";

const Ecoregimenes = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//cursos.itagraformacion.com/form/generate.js?id=1699";
        script.type = "text/javascript";
        script.async = true;
        document.getElementById("formulario-script").appendChild(script);
    }, []);

    return (
        <Container id="ecoregimenes" className="text-start border-bottom programas">
            <Row>
                <h4>Curso gratuito: Eco-regímenes orientados a las superficies de pastos</h4>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={ecoregimenes} />
                        <Card.Header>
                            <p>TÍTULO DE LA ACCIÓN: Eco-regímenes orientados a las superficies de pastos</p>
                            <p>MODALIDAD: aula virtual (online)</p>
                            <p>FECHAS: del 25 al 27 de junio de 2024</p>
                            <p>HORARIO: de 16:30 a 20:30</p>
                            <p>Nº DE HORAS: 12 Nº DE PLAZAS OFERTADAS: 50</p>
                            <p>DESTINATARIOS: Trabajadores del sector agrario y agroalimentario e interesados en incorporarse a estos sectores.</p>
                            <p>ORGANIZA: Fundación San Isidro Micas</p>
                            <p>REQUISITOS DE ACCESO: Para poder realizar este curso es necesario ser residente en Castilla y León</p>
                            <p>ESTE CURSO ES TOTALMENTE GRATUITO, REALIZA TU INSCRIPCIÓN ANTES DE QUE SE AGOTEN LAS PLAZAS.</p>
                        </Card.Header>
                        <ListGroup variant="flush">
                            <ListGroup.Item><p><strong>ESTA OPERACIÓN ESTÁ COFINANCIADA POR LA UNIÓN EUROPEA A TRAVÉS DEL FEADER EN UN 55%.</strong></p></ListGroup.Item>
                            <ListGroup.Item><strong>Objetivos:</strong>
                                <ul>
                                    <li>Mejorar la gestión y la sostenibilidad de las superficies de pastos</li>
                                    <li>Ampliar conceptos prácticos de aplicación de las prácticas a realizar sobre las superficies de pastos incluidas en los eco-regímenes de la PAC</li>
                                    <li>Evitar la pérdida de hábitats y especies</li>
                                </ul>
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Contenidos:</strong>
                                <ul>
                                    <li>Eco-regímenes en las superficies de pastos "Agricultura de Carbono y Agroecología en las superficies de pastos, Pastoreo extensivo, siega y Biodiversidad”</li>
                                    <li>Requisitos y compromisos de la Práctica de “Pastoreo Extensivo”</li>
                                    <li>Agricultura de Carbono: Aumento capacidad de sumidero de Carbono de los suelos Requisitos y compromisos de la “Práctica de establecimiento de Islas de Biodiversidad y Siega sostenible”</li>
                                </ul>
                            </ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Card.Text>
                                <strong>Formulario de inscripción</strong>
                                <br />
                                <br />
                            </Card.Text>
                            <div id="formulario-script"></div>
                            <Card.Img variant="bottom" src={placa} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Ecoregimenes;

import React from 'react';
import { Row, Col, Container, Card, ListGroup } from "react-bootstrap";
import horticultura2 from "../images/horticultura2.jpg";
import horti2 from "../images/programa-formacion-horticultura-2.jpg";
import huella from "../images/huella.jpg";
import Button from 'react-bootstrap/Button';
import cultiva from '../images/logo-programa-cultiva.jpg';

function Programas(){
    return (
        <Container id="programas" className="border-bottom programas">
            <Row>
                <Col>
                <Card>
                    <a href="https://programacultiva2023.fundacionsanisidromicas.org/">
                        <Card.Img variant="top" src={cultiva}/>
                    </a>
                        <Card.Header><Button href="https://programacultiva2023.fundacionsanisidromicas.org/" variant="success">Mas información e inscripciones</Button>{' '}</Card.Header>
                    </Card>
                </Col>
            </Row>
            <Row>
                <h4>Programa Mixto de Formación y Empleo Horticultura ecosolidaria II</h4>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={horticultura2} />
                        <Card.Header><h6>Horticultura ecosolidaria II, en Amayuelas de Abajo</h6><br></br></Card.Header>
                        <ListGroup variant="flush">
                            <ListGroup.Item>1 año de duración (del 1 de marzo de 2023 al 29 de febrero de 2024)</ListGroup.Item>
                            <ListGroup.Item><strong>Formación en horticultura ecológica</strong></ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                        <Card.Text>
                            El trabajo que irán desarrollando los alumnos consiste en producir verduras en ecológico para donarlas a familias necesitadas del entorno cercano.<br></br>
                        </Card.Text>
                        <Button href="https://fundacionsanisidromicas.com/recursos/Xerox Scan_17022023111148.pdf" variant="success">Ver resolución "partcipantes admitidos"</Button>{' '}
                        </Card.Body>
                        <Card.Img variant="bottom" src={horti2} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <h4>Sigue la huella verde</h4>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={huella} />
                        <Card.Header><h6>Proyecto “Sigue la Huella Verde”</h6><br></br></Card.Header>
                        <Card.Body>
                        <Card.Text>
                        El Proyecto “Sigue la Huella Verde” inicia su andadura en el año 2013 en la provincia de León con unos integrantes preocupados por los desafíos medioambientales, especialmente el que tiene que ver con el reciclaje de los restos orgánicos generados por todos, tanto en el mundo urbano como rural.<br></br>
                        Esto a llevado durante todo este tiempo a realizar actividades relacionadas con la educación medioambiental, la formación, el diseño y asesoramiento de campañas de compostaje. 
                        Siguiendo con todo este trabajo realizado y queriendo darle más proyección y solidez, nos hemos unido varias entidades de ámbito autonómico (Castilla y León), para seguir con la labor iniciada.<br></br>
                        </Card.Text>
                        <Button href="https://www.siguelahuellaverde.com/" variant="success">Ver Proyecto</Button>{' '}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Programas;
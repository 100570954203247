import { useState } from 'react'
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Carousel() {
  const [sliderRef, setSliderRef] = useState(null)

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const postCards = [
    {
      imageSrc:'../images/blog/radiolumbre.jpg',
      title: 'Charla con las participantes',
      description: 'Programa mixto de formación y empleo “Horticultura eco-solidaria”. Charla con las participantes.”',
      URL: 'https://fundacionsanisidromicas.com/blog/radiolumbre.html'
    },
    {
      imageSrc:'../images/blog/premio-fuentes-claras-2022.jpg',
      title: 'Premios Fuentes Claras 2022',
      description: 'Primer premio en la modalidad de asociaciones y entidades sin ánimo de lucro por su proyecto “Horticultura Ecosolidaria.”',
      URL: 'https://fundacionsanisidromicas.com/blog/premios-fuentes-claras-2022.html'
    },
    {
      imageSrc:'../images/blog/surcos.jpg',
      title: 'Candidatura Premios Surcos 2022',
      description: 'Surcos se hace eco de nuestro proyecto de  Horticultura Ecosolidaria y tenemos el honor de ser candidatos a los premios de este año.',
      URL: 'https://fundacionsanisidromicas.com/blog/premios-surcos-2022.html'
    },
    {
      imageSrc:'../images/blog/san_vicente_tardon.jpeg',
      title: 'San Vicente Tardón 2022',
      description: 'Os invitamos a tod@s a este fin de semana en Amayuelas de Abajo en donde podréis disfrutar de actividades para todas las edades.',
      URL: 'https://fundacionsanisidromicas.com/blog/san-vicente-tardon-2022.html'
    },
    {
      imageSrc:'../images/blog/recoleccion.jpeg',
      title: 'Recolección y preparación de cestas',
      description: 'Para finalizar recolectamos repollos, lechugas, achicorias y acelgas, una vez preparadas hacemos las cestas',
      URL: 'https://fundacionsanisidromicas.com/blog/recoleccion.html'
    },
    {
      imageSrc:'../images/blog/riego.jpeg',
      title: 'Sistema de riego, riego por goteo',
      description: 'Utilizamos riego por goteo, por lo que tenemos que asegurar que todos los goteros funcionan a la perfección',
      URL: 'https://fundacionsanisidromicas.com/blog/riego.html',
    },
    {
      imageSrc:'../images/blog/herbario.jpeg',
      title: 'Preparación de un herbario',
      description: 'Preparando un herbario para conocer las especies más comunes de adventicias de la zona.',
      URL: 'https://fundacionsanisidromicas.com/blog/herbario.html',
    },
    {
      imageSrc:'../images/blog/fundacion_personas.jpg',
      title: 'Taller sobre orientación laboral',
      description: 'El viernes 25 de marzo hemos tenido un  taller sobre orientación laboral impartido por la Fundación Personas',
      URL: 'https://fundacionsanisidromicas.com/blog/orientacion-laboral.html',
    },
    {
      imageSrc:'../images/blog/planificar-huerta.jpeg',
      title: 'Planificación de la huerta',
      description: 'Planificar una huerta, mediante rotaciones y asociaciones de cultivos, organizando las especies que vamos a cultivar esta campaña',
      URL: 'https://fundacionsanisidromicas.com/blog/planificacion-huerta.html',
    },
    {
      imageSrc:'../images/blog/la-fabrica.jpg',
      title: 'Centro de formación y emprendimiento "La fábrica"',
      description: 'Formación en albañilería y fontanería, con espacio de trabajo compartido para emprendedores rurales',
      URL: 'https://fundacionsanisidromicas.com/blog/la-fabrica-formacion-alanileria-fontaneria.html',
    },
    {
      imageSrc:'../images/blog/horticultura-ecosolidaria.jpeg',
      title: 'Horticultura ecosolidaria',
      description: 'Programa mixto de Formación y Empleo del ECYL, denominado “horticultura ecosolidaria”, en Amayuelas de Abajo',
      URL: 'https://fundacionsanisidromicas.com/blog/horticultura-ecosolidaria.html',
    },
    {
      imageSrc:'../images/blog/zanja.jpeg',
      title: 'Semana del 28 al 1 de abril',
      description: 'Hemos aprendido en clase aspectos sobre planificación de la huerta, asociaciones y rotaciones, mecánica del tractor, factores climáticos...',
      URL: 'https://fundacionsanisidromicas.com/blog/semana28-1abril.html',
    },
  ]

  return (
    <div className='contentcarousel'>
      <div className='controlscarousel'>
        <button onClick={sliderRef?.slickPrev}>
          <FaChevronLeft />
        </button>
        <button onClick={sliderRef?.slickNext}>
          <FaChevronRight />
        </button>
      </div>
      <Slider ref={setSliderRef} {...sliderSettings}>
        {postCards.map((card, index) => (
          <div key={index} className='cardcarousel'>
            <img src={card.imageSrc} alt={card.title} className='cardcarousel-image' />
            <div className='textcarousel-info'>
              <div className='cardcarousel-header'>
                <h2>{card.title}</h2>
                <span>{card.pricingText}</span>
              </div>
              <p>{card.description}</p>
            </div>
            <div className='cardcarousel-footer'>
                <button><a href={card.URL}>Leer más</a></button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
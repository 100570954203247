import React from 'react';
import { Row, Container } from "react-bootstrap";
import Carousel from '../components/carousel';

function Blog(){
    return (
        <Container id="blog" className="border-bottom programas">
            <Row>
                <h3>Novedades:</h3>
                <Carousel></Carousel>
            </Row>
        </Container>
    );
}

export default Blog;
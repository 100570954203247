import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import Paypal from "../images/boton-paypal.jpg";

function Donacion () {
    return (
        <Container className="border-bottom contactar">
                <Row id="donar"><h3>Colabora con nosotros haciendo una donación</h3></Row>
                <Row>
                  <Col md={{ span: 6, offset: 3 }}>
                  <a href="https://www.paypal.com/donate/?hosted_button_id=F3CJT95DK638Q"><img src={Paypal} alt="haz una donación"></img></a>
                  </Col>
                </Row>
        </Container>
    )
}

export default Donacion